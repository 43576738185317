window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');


    //jquery.easing
    //require('jquery.easing/jquery.easing')

    // jquery-ui
    //require('jquery-ui/ui/widgets/tooltip');
    //require('jquery-ui/ui/widgets/datepicker');

    // highcharts
    /*const Highcharts = require('highcharts');

    require('highcharts/highcharts-3d')(Highcharts);
    require('highcharts/highcharts-more')(Highcharts);
    require('highcharts/modules/variable-pie')(Highcharts);
    require('highcharts/modules/solid-gauge')(Highcharts);
    require('highcharts/modules/series-label')(Highcharts)
    require('highcharts/modules/drilldown')(Highcharts);
    require('highcharts/modules/exporting')(Highcharts);
    require('highcharts/modules/export-data')(Highcharts);
    require('highcharts/modules/accessibility')(Highcharts);
    require('highcharts/modules/bullet')(Highcharts);
    require('highcharts/modules/gantt')(Highcharts);

    window.Highcharts = Highcharts;*/


    // datatables
    window.JSZip = require( 'jszip' );
    require( 'datatables.net-bs4' );
    require( 'datatables.net-buttons-bs4' );
    require( 'datatables.net-buttons/js/buttons.html5.js' );
    require( 'datatables.net-buttons/js/buttons.print.js' );
    require( 'datatables.net-responsive-bs4' );
    require('datatables.net-select-bs4');
    require('datatables.net-rowgroup-bs4');
    require('datatables.net-fixedheader-bs4');

    // pdfMake
    var pdfMake = require('pdfmake/build/pdfmake.js');
    var pdfFonts = require('pdfmake/build/vfs_fonts.js');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    // select2
    require('select2');

    // sweetalert2
    const Swal = window.Swal = require('sweetalert2');


} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//window.axios.defaults.withCredentials = true;
//axios.defaults.withCredentials = true;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
